<script setup>
import { useForm } from "@inertiajs/vue3";
import { useDebounceFn } from "@vueuse/core";
import { computed, inject, ref } from "vue";
import { useI18n } from "vue-i18n";

// Components
import Pagination from "@/Components/Pagination/PaginationFE.vue";
import Filters from "@/Components/Table/Filters.vue";
import Table from "@/Components/Table/Table.vue";

// Layout
import LocationLayout from "@/Layouts/LocationLayout.vue";

// Partials
import ModalApproveHours from "./Partials/ModalApproveHours.vue";
import { IdentificationIcon } from "@heroicons/vue/20/solid";

const { t } = useI18n();
const route = inject("route");
const dayjs = inject("dayjs");

const props = defineProps({
  query: Object,
  shifts: Object,
  statusOptions: Object,
  weekOptions: Object
});

const showHoursForm = ref(false);
const currentRowObject = ref({
  index: null,
  id: null,
  nextId: null,
  shift: null
});

const allOpenShiftHours = computed(() =>
  props.shifts.data
    .filter(shift => shift.status !== "accredited")
    .map(shift => shift.id)
);

const cellClicked = ({ row, column, id }) => {
  if (column != tableSettings.length - 1) {
    return;
  }
  showHoursForm.value = true;
  const shiftId = currentRowObject.value.id; // Make sure 'id' is the correct value for 'approve_hour'
  route("location.approve-hours.show", { approve_hour: shiftId }); // Pass the parameter as an object
};

const sendNextRow = () => {
  if (allOpenShiftHours.value.length == 0) {
    return;
  }

  const currentRowId = currentRowObject.value.id;
  const currentIndexOpenShiftHoursId = allOpenShiftHours.value.indexOf(
    currentRowId
  );
  const nextId = allOpenShiftHours.value[currentIndexOpenShiftHoursId + 1];

  currentRowObject.value = {
    id: nextId,
    index: props.shifts.data.indexOf(nextId),
    nextId: allOpenShiftHours.value[currentIndexOpenShiftHoursId + 2],
    shift: props.shifts.data.find(shift => shift.id == nextId)
  };
};

const form = useForm({
  search: props.query.search ?? "",
  orderBy: props.query.orderBy ?? "start",
  orderDir: props.query.orderDir ?? "desc",
  status: props.query.status ?? "completed",
  week: props.query.week ?? "",
  page: props.query.page ?? 1
});

const getData = (resetPage = true) => {
  form.page = resetPage ? 1 : form.page;

  form.get(route("location.approve-hours.index"), {
    preserveState: true
  });
};

const debouncedGetData = useDebounceFn(() => getData(), 300);

const setPage = e => {
  form.page = e;
  getData(false);
};

const typeFilters = {
  status: {
    options: {
      "": t("All {attributes}", { attributes: t("statuses") }),
      ...props.statusOptions
    }
  },
  week: {
    options: { "": t("Week"), ...props.weekOptions }
  }
};

const tableSettings = [
  { label: t("Date"), width: "15%", cell: "Bold", orderBy: "start" },
  { label: t("Time"), width: "15%" },
  { label: t("Week"), width: "5%" },
  { label: t("Function"), orderBy: "position.name", width: "20%" },
  { label: t("Fl@xer"), width: "10%" },
  { label: t("Calling name"), width: "10%" },
  { label: t("Rating"), orderBy: "rating", cell: "Rating", width: "15%" },
  { label: "", width: "5%", cell: "Arrow" }
];

const tableData = computed(() =>
  props.shifts.data.map(shift => {
    return {
      id: shift.id,
      colorCode: shift.status === "accredited" ? "#1BB21B" : "#F89B29",
      rowData: [
        dayjs(shift.start).format("dd DD-MM-YYYY"),
        dayjs(shift.start).format("HH:mm") +
          " - " +
          dayjs(shift.end).format("HH:mm"),
        dayjs(shift.start).isoWeek(),
        shift.position,
        [
          shift.selected_applicant?.first_name,
          shift.selected_applicant?.last_name
        ].join(" "),
        shift.selected_applicant?.nickname,
        [shift.review?.rating],
        route("location.approve-hours.show", shift.id)
      ]
    };
  })
);
</script>

<template>
  <LocationLayout narrow title="Flexable" :h1="$t('Approve Hours')">
    <Filters
      :h1="$t('Approve Hours')"
      :form="form"
      :typeFilters="typeFilters"
      @anyUpdate="getData()"
      @searchUpdate="debouncedGetData()"
    />

    <template v-if="typeof shifts !== 'undefined'">
      <Table
        class="mb-8"
        :loading="form.processing"
        :tableSettings="tableSettings"
        :data="tableData"
        v-model:orderBy="form.orderBy"
        @update:orderBy="getData()"
        v-model:orderDir="form.orderDir"
        @update:orderDir="getData()"
        :linkOpensPopup="true"
        :shifts="shifts"
      />
    </template>
    <template v-else>
      <div
        class="w-full py-3 overflow-hidden text-base leading-tight bg-white rounded min-h-20 text-blue"
      >{{ $t('No {model} found', { model: $t(`Fl@xr's`) }) }}</div>
    </template>

    <Pagination
      v-if="typeof shifts !== 'undefined'"
      :links="shifts.links"
      @setPage="setPage"
      :pageData="shifts"
      class="mt-10"
    />

    <!-- <ModalApproveHours
      v-model:showing="showHoursForm"
      :shift="currentRowObject.shift"
      @next-row-clicked="sendNextRow"
      @marked-as-no-show="showHoursForm = false"
      :hideNextButton="typeof currentRowObject.nextId === 'undefined'"
    />-->
  </LocationLayout>
</template>
